import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'semantic-ui-css/semantic.min.css'
import './styles/site.scss'
import 'react-datepicker/dist/react-datepicker.css'

const setMobileAuthToken = async () => {
    try {
        const localAuth = localStorage.getItem('mobileAuth')
        const sessionAuth = sessionStorage.getItem('auth')
        if (localAuth && !sessionAuth) {
            sessionStorage.setItem('auth', localAuth)
        }
    } catch (error) {}
}
setMobileAuthToken()

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, { lazy, Suspense } from 'react'
import styles from './App.module.scss'

const CsrApp = lazy(() => import('./CsrApp'))
const EndpointApp = lazy(() => import('./EndpointApp'))
const isEndpoint = window.location.pathname.match(/^\/endpoint\/(.*)\/(.*)$/) || window.location.pathname.match(/^\/video-only\/(.*)$/)
window.useLocalSignalR = false

const App = () => {
    return (
        <Suspense fallback={<div className={styles.loadingBackground}></div>}>
            {!isEndpoint ? <CsrApp /> : <EndpointApp />}
        </Suspense>
    )
}

export default App
